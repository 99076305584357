import { Routes, Route } from "react-router-dom";
import Home from "../pages/home/home";
import NotFound from "../pages/error/404";
import Blog from "../pages/blog/blog";
import BlogDetail from "../pages/blog/blog-detail";

const Routing = (params) => {
  return (
    <Routes>
      <Route exact path={"/"} element={<Home />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:path" element={<BlogDetail />} />
      <Route path={"*"} element={<NotFound />} />
    </Routes>
  );
};
export default Routing;
