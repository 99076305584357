// *******~ Import ~******** //
// React
// Assets

// Components
import Slider from "./components/slider/slider";
import BusinessSolution from "./components/business-solution/business-solution";
import FusionSelling from "./components/fusionselling/fusionselling";
// import FMBintro from "./components/fmb-intro/fmb-intro";
import PartnersCount from "./components/partners-count/partners-count";
import CallBack from "./components/callback/callback";
import Merchants from "./components/merchants/merchants";
// import AllTypes from "./components/alltypes/alltypes";
// import ClientLogo from "./components/clients/clients";
import RecentBlog from "./components/recent-blog/recent-blog";
// CSS
import "./home.scss";
// Images
// Icons
// SEO
import HomeMetData from "./home-seo";
// *******~ Import ~******** //
export default function Home(second) {
  return (
    <>
      <section className="home-page">
        <HomeMetData />
        <Slider />
        <BusinessSolution />
        {/* <FMBintro /> */}
        <FusionSelling />
        <PartnersCount />
        {/* <AllTypes /> */}
        <Merchants />
        {/* <ClientLogo /> */}
        <RecentBlog />
        <CallBack />
      </section>
    </>
  );
}
