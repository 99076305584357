// *******~ Import ~******** //
// React
// Assets
import { Container, Row, Col } from "react-bootstrap";
// Components
// CSS
import "./partners-count.scss";
// Images
// Icons
// *******~ Import ~******** //
export default function PartnersCount(second) {
  return (
    <>
      <section className="partners-count">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div className="partners-content">
                <h1>TRUSTED BY 5000+</h1>
                <p>BUSINESSES OF ALL SIZES</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
