// *******~ Import ~******** //
// React
// Assets
import { Container, Row, Col, Image } from "react-bootstrap";
// Components
// CSS
import "./business-solution.scss";
// Images
import BSImg1 from "./img/business-solution-1.png";
import BSImg2 from "./img/business-solution-2.png";
import BSImg3 from "./img/business-solution-3.png";
import BSImg4 from "./img/business-solution-4.png";
import BSImg5 from "./img/business-solution-5.png";
import BSImg6 from "./img/business-solution-6.png";
import ArrowIcon1 from "./img/arrow1.svg";
import ArrowIcon2 from "./img/arrow2.svg";
// Icons

// *******~ Import ~******** //
export default function BusinessSolution(second) {
  const Data = [
    {
      title: "Point Of Sale",
      desc: "Streamline your in-store and online business with the industry's most advanced Point of Sale platform",
      path: "/",
      img: BSImg1,
      darkcontent: false,
    },
    {
      title: "Online Store",
      desc: "Fast-track your journey into the online world with a personalized e-commerce website in no time",
      path: "/",
      img: BSImg2,
      darkcontent: true,
    },
    {
      title: "QR Solutions",
      desc: "Unlock a touch-free shopping experience: Use your store's QR code for ordering, payments, and beyond",
      path: "/",
      img: BSImg3,
      darkcontent: true,
    },
    {
      title: "Marketplace Integrations",
      desc: "Take your business further by leveraging the power of marketplace integrations",
      path: "/",
      img: BSImg4,
      darkcontent: false,
    },
    {
      title: "Payments",
      desc: "Streamlined payment experiences: Speed, security, and multiple options for customer convenience",
      path: "/",
      img: BSImg5,
      darkcontent: false,
    },
    {
      title: "Delivery",
      desc: "Deliver excellence in every step with deliveries and logistics integration, in collaboration with our trusted partners",
      path: "/",
      img: BSImg6,
      darkcontent: true,
    },
  ];
  return (
    <>
      <section className="business-solution">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <h1 className="title" data-aos="fade-up">
                Customizable solutions for thriving in the <br />
                world of omni-channel selling
              </h1>
            </Col>
          </Row>
          {Data.reduce((rows, datas, index) => {
            if (index % 2 === 0) rows.push([]);
            rows[Math.floor(index / 2)].push(datas);
            return rows;
          }, []).map((row, rowIndex) => (
            <Row key={rowIndex} className="business-row justify-content-center">
              {row.map((datas, colIndex) => (
                <Col
                  key={colIndex}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={5}
                  xl={5}
                  xxl={5}
                >
                  <div className="business-box">
                    <div
                      className={`content ${datas.darkcontent ? "dark" : ""}`}
                    >
                      <h3 className="heading">{datas.title}</h3>
                      <p>{datas.desc}</p>
                      <button>
                        Discover More
                        <img
                          src={datas.darkcontent ? ArrowIcon2 : ArrowIcon1}
                          alt=""
                        />
                      </button>
                    </div>
                    <div className="img-box">
                      <Image src={datas.img} fluid />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ))}
        </Container>
      </section>
    </>
  );
}
