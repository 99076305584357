import React, { useState } from "react";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Container, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineCancel } from "react-icons/md";

function GetStartModal(props) {
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formState, setFormState] = useState({
    name: "",
    phone: "",
    email: "",
    businessName: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let errors = { ...formErrors };

    switch (name) {
      case "name":
        errors.name = value.length < 1 ? "Name is required" : "";
        break;
      case "phone":
        errors.phone =
          value.length !== 11 || !/^\d+$/.test(value)
            ? "Phone number must be 11 digits"
            : "";
        break;
      case "email":
        errors.email = !/\S+@\S+\.\S+/.test(value)
          ? "Invalid email address"
          : "";
        break;
      case "businessName":
        errors.businessName =
          value.length < 1 ? "Business name is required" : "";
        break;
      default:
        break;
    }

    setFormErrors(errors);
    setFormState({ ...formState, [name]: value });
  };

  const isFormValid = () => {
    return Object.values(formErrors).every((error) => error === "");
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      toast.error("Please fill in all required fields correctly", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
        delay: 500,
      });
      return;
    }
    setLoading(true);
    axios
      .post("https://api.fusioninnovative.com/fmbEnquiry", formState, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: false,
      })
      .then((response) => {
        if (response.data.status === false) {
          toast.error(response.data.data, {
            position: toast.POSITION.TOP_CENTER,
            className: "toast-message",
            delay: 500,
          });
        } else {
          console.log(response.data);
          toast.success("Form submitted successfully!", {
            position: toast.POSITION.TOP_CENTER,
            className: "toast-message",
            delay: 500,
          });
          setFormState({
            name: "",
            phone: "",
            email: "",
            businessName: "",
          });
          setShow(false);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <button onClick={handleShow} className={props.clsname}>
        {props.text}
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="get-start-modal"
      >
        <Modal.Header>
          <Modal.Title>Connect with a Fusion Expert</Modal.Title>
          <span onClick={handleClose}>
            <MdOutlineCancel />
          </span>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Form noValidate onSubmit={sendEmail}>
                <Col xs={12} md={12}>
                  <FloatingLabel controlId="floatingInput" label="Your Name">
                    <Form.Control
                      type="text"
                      placeholder="Your Name"
                      autoFocus
                      required
                      name="name"
                      value={formState.name}
                      onChange={handleInputChange}
                      isInvalid={!!formErrors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.name}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col xs={12} md={12}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Contact Number"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Contact Number"
                      required
                      name="phone"
                      value={formState.phone}
                      onChange={handleInputChange}
                      maxLength={11}
                      pattern="[0-9]{11}"
                      isInvalid={!!formErrors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.phone}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col xs={12} md={12}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email address"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Email address"
                      required
                      name="email"
                      value={formState.email}
                      onChange={handleInputChange}
                      isInvalid={!!formErrors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.email}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col xs={12} md={12}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Your Business Name"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Your Business Name"
                      required
                      name="businessName"
                      value={formState.businessName}
                      onChange={handleInputChange}
                      isInvalid={!!formErrors.businessName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.businessName}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>

                <Col xs={12} md={12}>
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? "Loading..." : "Request Call back"}
                  </Button>
                </Col>
              </Form>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GetStartModal;
