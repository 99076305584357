import React from "react";
import { Helmet } from "react-helmet";

export default function BlogSEO({ title, description, url }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="point of sale solutions, booking management, QR code solutions, free online store setup, delivery service, digital payments, food and beverage, free POS hardware, comission free service, No contracts, no hidden fees"
      />
      <meta name="author" content="Fusion Inc." />
      {/* Facebook */}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`https://fusionmybusiness.com/blog/${url}`}
      />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="https://fusionmybusiness.com/img/fmb-logo-dark.svg"
      />
      <meta property="og:site_name" content="Fusion My Business" />
      {/* Facebook */}
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content="https://fusionmybusiness.com/img/fmb-logo-dark.svg"
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:creator" content="Fusion" />
      <meta name="twitter:site" content="Fusion" />
      <meta name="twitter:description" content={description} />
      {/* Twitter */}
      <link rel="canonical" href={`https://fusionmybusiness.com/blog/${url}`} />
    </Helmet>
  );
}
