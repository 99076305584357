import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Routing from "./router/router";
import Header from "./common/header/header";
import Footer from "./common/footer/footer";
import WhatsAppChat from "./common/whatsapp-chat/whatsapp-chat";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoTop from "./common/gototop/gototop";
import ScrollToTop from "./common/scrolltop/scrolltop";
export default function Main(params) {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Router basename={"/"} >
        {/* <Router> */}
        <GoTop />
        <ScrollToTop />
        <ToastContainer autoClose={5000} transition={Flip} />
        <WhatsAppChat />
        <Header />
        <main>
          <Routing />
        </main>
        <Footer />
      </Router>
    </>
  );
}
