// *******~ Import ~******** //
// React
import React, { useEffect, useState } from "react";
// Assets
import { Container, Row, Col, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import BlogSEO from "./blog-seo";

// Components
import BlogPostData from "./blogdata.json";
// CSS
import "./blog-detail.scss";
// Images
import FBimg from "./img/fb.svg";
import TWimg from "./img/twitter.svg";
import YTimg from "./img/yt.svg";
import INimg from "./img/insta.svg";
import LIimg from "./img/in.svg";
import AppleIcon from "./img/FusionPOS-apple-store.png";
import AndroidIcon from "./img/FusionPOS-android-app.png";
// Icons
// *******~ Import ~******** //
export default function BlogDetail(second) {
  const { path } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  useEffect(() => {
    // Simulating fetching the blog post from an API
    const fetchBlogPost = async () => {
      try {
        // Perform the API call or fetch the blog post using the 'id'
        // const response = await fetch(`/api/blog/${id}`);
        // const data = await response.json();
        // setBlogPost(data);

        // Assuming you have a hardcoded array of blog posts

        const foundPost = BlogPostData.find((post) => post.path === path);
        setBlogPost(foundPost);
      } catch (error) {
        console.log("Error fetching blog post:", error);
      }
    };

    fetchBlogPost();
  }, [path]);
  return (
    <>
      {blogPost ? (
        <>
          <BlogSEO
            title={blogPost.meta.title}
            description={
              blogPost.desc[0].subdesc.join(" ").substring(0, 300) + "..."
            }
            url={blogPost.path}
          />
          <section className="blog-details">
            <Container>
              <Row className="justify-content-center">
                <Col md={12} lg={6} xl={6} xxl={6}>
                  <Image
                    src={blogPost.img}
                    fluid
                    className="main-img"
                    alt={`Fusion My Business | ${blogPost.title}`}
                    title={`Fusion My Business | ${blogPost.title}`}
                  />
                  <div class="date-icon">
                    <p>
                      By
                      <a href={blogPost.url} target="_blank" rel="noreferrer">
                        {" "}
                        {blogPost.by}{" "}
                      </a>{" "}
                      |<span> {blogPost.date} </span>
                    </p>
                    <div class="social-icon">
                      <a
                        href="https://www.facebook.com/FusionPOS/"
                        target="_black"
                        rel="noreferrer"
                      >
                        <Image src={FBimg} />
                      </a>
                      <a
                        href="https://twitter.com/Fusion_POS"
                        target="_black"
                        rel="noreferrer"
                      >
                        <Image src={TWimg} />
                      </a>
                      <a
                        href="https://www.youtube.com/watch?v=HDrbZN_75KM&amp;feature=youtu.be"
                        target="_black"
                        rel="noreferrer"
                      >
                        <Image src={YTimg} />
                      </a>
                      <a
                        href="https://www.instagram.com/accounts/login/?next=/fusion.pos/"
                        target="_black"
                        rel="noreferrer"
                      >
                        <Image src={INimg} />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/fusionposuk"
                        target="_black"
                        rel="noreferrer"
                      >
                        <Image src={LIimg} />
                      </a>
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={6} xl={6} xxl={6}>
                  <div className="aside-content">
                    <h1>{blogPost.title}</h1>

                    {blogPost.desc[0] && (
                      <div className="content-div">
                        {blogPost.desc[0].subtitle && (
                          <h3>{blogPost.desc[0].subtitle}</h3>
                        )}
                        <ul>
                          {blogPost.desc[0].subdesc.map(
                            (subdescription, index) => (
                              <li key={index}>
                                <p>{subdescription}</p>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="main-content">
                <Col xxl={12} md={12}>
                  {blogPost.desc.slice(1).map((descData, index) => (
                    <>
                      <div className="content-div" key={index}>
                        {descData.subtitle && (
                          <h3>{parse(descData.subtitle)}</h3>
                        )}
                        <ul className={descData.listType && "custom-list"}>
                          {descData.subdesc.map((subdescription, subIndex) => (
                            <li key={subIndex}>
                              <p>{parse(subdescription)}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  ))}
                  <div className="content-div">
                    <h3>
                      Experience the Enhanced Fusion My Business App Today
                    </h3>
                    <ul>
                      <li>
                        <p>
                          Download the latest version of Fusion business app to
                          check out the new user interface.
                        </p>
                        <p className="download-app">
                          {" "}
                          <a
                            href="https://apps.apple.com/in/app/fusion-partner/id1502807167"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={AppleIcon} alt="Fusion App" />
                          </a>
                          <a
                            href="https://play.google.com/store/apps/details?id=in.fusionpos.fusionpartner"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={AndroidIcon} alt="Fusion App" />
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      ) : (
        <p>Blog post not found.</p>
      )}
    </>
  );
}
