import React from "react";
import { Helmet } from "react-helmet";

export default function HomeMetData() {
  return (
    <Helmet>
      <title>Fusion: Your Path to Business Success Starts Here</title>
      <meta
        name="description"
        content="Start your journey to business success with Fusion. Discover secure payment processing, advanced point of sale solutions, Booking management, QR code solutions and a free online store setup with Delivery service. Join thousands of satisfied users and unlock your business potential with Fusion today!"
      />
      <meta
        name="keywords"
        content="point of sale solutions, booking management, QR code solutions, free online store setup, delivery service, digital payments, food and beverage, free POS hardware, comission free service, No contracts, no hidden fees"
      />
      <meta name="author" content="Fusion Inc." />
      <link rel="canonical" href="https://fusionmybusiness.com" />

      <meta
        property="og:title"
        content="Fusion: Your Path to Business Success Starts Here"
      />
      <meta property="og:url" content="https://fusionmybusiness.com" />
      <meta http-equiv="content-language" content="en" />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Fusion" />
      <meta
        property="og:description"
        content="Start your journey to business success with Fusion. Discover secure payment processing, advanced point of sale solutions, Booking management, QR code solutions and a free online store setup with Delivery service. Join thousands of satisfied users and unlock your business potential with Fusion today!"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="Fusion" />
      <meta name="twitter:creator" content="Fusion" />
      <meta name="twitter:domain" content="https://fusionmybusiness.com" />
    </Helmet>
  );
}
