// *******~ Import ~******** //
// React
import { Link } from "react-router-dom";
// Assets
import { Container, Row, Col, Image } from "react-bootstrap";
// Components
import BlogPostData from "./blogdata.json";
// CSS
import "./blog.scss";
// Images
// Icons
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
// *******~ Import ~******** //
export default function Blog(second) {
  return (
    <>
      <section className="blog-page">
        <Container>
          <Row>
            {BlogPostData.map((post, index) => (
              <>
                <Col key={index} xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                  <div className="main-div">
                    <Link className="read-more-btn" to={`/blog/${post.path}`}>
                      <Image className="blog-img" src={post.img} fluid />
                    </Link>

                    <div className="content-div">
                      <h1>{post.title}</h1>
                      <p className="author">
                        By
                        <a href={post.url} target="_blank" rel="noreferrer">
                          {" "}
                          {post.by}{" "}
                        </a>{" "}
                        |<span> {post.date} </span>
                      </p>
                      <p className="sub-des">
                        {post.desc[0].subdesc.join(" ").substring(0, 300) +
                          "..."}
                      </p>
                      <Link className="read-more-btn" to={`/blog/${post.path}`}>
                        {" "}
                        Read More <MdOutlineKeyboardDoubleArrowRight />
                      </Link>
                    </div>
                  </div>
                </Col>
              </>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
