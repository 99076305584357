// *******~ Import ~******** //
// React
import React, { useState, useEffect, useContext } from "react";
// Assets

import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

// Components
import ThemeContext from "../theme/components/contexts/themecontexts";
import MenuBtn from "./menubtn/menubtn";
import GetStartModal from "./get-start/get-start";
// import ThemeBtn from "../theme/components/themeshetter";
// CSS
import "./css/header.scss";
import "./get-start/get-start.scss";
// Images
import LogoLight from "./img/fmb-logo-white.svg";
import LogoDark from "./img/fmb-logo-dark.svg";
// Responsive Img

// Icons

// *******~ Import ~******** //

const Header = (params) => {
  const { theme } = useContext(ThemeContext);
  // Scroll add class start
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0);
    });
  }, []);
  // Scroll add class End
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        sticky="top"
        className={`main-header ${!scroll ? "theme-bg" : "gray-bg"}`}
      >
        <Container>
          <div className="logo">
            {theme === "light" ? (
              <>
                <Nav.Link as={Link} to={"/"} eventKey={0}>
                  {!scroll ? (
                    <>
                      <Image src={LogoLight} alt="" />
                    </>
                  ) : (
                    <>
                      <Image src={LogoDark} alt="" />
                    </>
                  )}
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link as={Link} to={"/"} eventKey={0}>
                  {!scroll ? (
                    <>
                      <Image src={LogoLight} alt="" />
                    </>
                  ) : (
                    <>
                      <Image src={LogoDark} alt="" />
                    </>
                  )}
                </Nav.Link>
              </>
            )}
          </div>
          <MenuBtn />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="nav-menu-div">
              <NavDropdown title="Products" id="collasible-nav-dropdown">
                <NavDropdown.Item as={Link} to="/">
                  Point of Sale (POS)
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="#action/3.2">Action 2</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Action 3</NavDropdown.Item> */}
              </NavDropdown>
              <Nav.Link as={Link} to="/">
                Business
              </Nav.Link>
              <Nav.Link as={Link} to="/">
                Why Fusion
              </Nav.Link>
              <Nav.Link as={Link} to="/">
                Integrations
              </Nav.Link>
              <Nav.Link as={Link} to="/">
                Resource
              </Nav.Link>
            </Nav>
            <Nav className="nav-login-div">
              <GetStartModal text="Demo" />
              <GetStartModal text="Free trail" />
              {/* <ThemeBtn /> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Header;
