// *******~ Import ~******** //
// React
// Assets
import { Container, Row, Col, Image } from "react-bootstrap";

// Components
// CSS
import "./merchants.scss";
// Images
import Quotes from "./img/quotes.svg";
// Icons
import { GrStar } from "react-icons/gr";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation, Autoplay } from "swiper";
// *******~ Import ~******** //
export default function Merchants(second) {
  const content = [
    {
      Name: "Harish Zoe Nuala",
      Desgi: "Co-Founder",
      Desc: "Pricing is reasonable and the Customer service is great. I strongly recommend to all the restaurant owners.",
    },
    {
      Name: "Ruby Taj",
      Desgi: "Accounts",
      Desc: "Excellent, immediate help available whenever needed. Friendly polite staff, always happy to help. Great service.",
    },
    {
      Name: "Natural Beats",
      Desgi: "Co-Founder",
      Desc: "Amazing 24/7 support by FusionPOS team. Much recommended and appreciated.",
    },
    {
      Name: "Chandan Mehra",
      Desgi: "Co-Founder",
      Desc: "Very good and approachable people, keep the good work going.",
    },
    {
      Name: "tiarnan games2",
      Desgi: "Co-Founder",
      Desc: "Very friendly keen to help customer service. Got me up and running very quickly. 3Frenchies.",
    },
  ];
  return (
    <>
      <section className="merchants">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <h1>Moments with our Merchants</h1>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Swiper
                modules={[Navigation, Autoplay]}
                slidesPerView={3}
                spaceBetween={30}
                navigation={true}
                grabCursor={true}
                loop={false}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                className="merchants-swiper"
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  576: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 25,
                  },
                }}
              >
                {content.map((data, index) => (
                  <SwiperSlide key={index}>
                    <div className="swiper-box">
                      <div className="header">
                        {/* <Image src="https://placeimg.com/171/180/any" /> */}
                        <div className="name-desg">
                          <p>{data.Name}</p>
                          <ul>
                            {[...Array(5)].map((star, index) => (
                              <>
                                <li key={index} id={index}>
                                  <GrStar />
                                </li>
                              </>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <p className="descri">"{data.Desc}"</p>
                      <span className="quotes-img">
                        <Image src={Quotes} />
                      </span>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
