// *******~ Import ~******** //
// React
// Assets
import { Container, Row, Col } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
// Components
import GetStartModal from "../../../../common/header/get-start/get-start";
// CSS
import "./callback.scss";
// Images
// Icons
// *******~ Import ~******** //
export default function CallBack(second) {
  return (
    <>
      <section className="call-back">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={6}>
              <h3>
                Build and grow your business <br /> with Fusion
              </h3>
              <p>
                Get all the tools you need to take your business to the <br />
                next level & join the millions of merchants using Fusion.
              </p>
              {/* <Form>
                <Form.Control type="text" placeholder="Enter Mobile Number" />
                <Button type="submit">Request Call Back</Button>
              </Form> */}
              <div className="fmb-btn-group">
                <GetStartModal text="Demo" />
                <GetStartModal text="Talk to Expert" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
