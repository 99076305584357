// *******~ Import ~******** //
// React
// Assets
// Components
import Main from "./main";
// import ThemeSetter from "./common/theme/components/themeshetter";
import ThemeProvider from "./common/theme/components/contexts/themeprovider";
// CSS
// Images
// Icons

// Google Analytics
// import { useEffect } from "react";
// import { initGA } from "./google-analytics";
// Google Analytical
// *******~ Import ~******** //
function App() {
  return (
    <>
      <ThemeProvider>
        {/* <ThemeSetter /> */}
        <Main />
      </ThemeProvider>
    </>
  );
}

export default App;
