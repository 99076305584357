// *******~ Import ~******** //
// React
import React, { useState } from "react";
// Assets
import { Container, Row, Col, Image } from "react-bootstrap";
// Components
// CSS
import "./fusionselling.scss";
// Images
import FoodImg1 from "./img/food-img-1.png";
import FoodImg2 from "./img/food-img-2.png";
import FoodImg3 from "./img/food-img-3.png";
import RetailImg1 from "./img/retailright-img-1.png";
import RetailImg2 from "./img/retailright-img-2.png";
import RetailImg3 from "./img/retailright-img-3.png";
import Services1 from "./img/services-img-1.png";
import Services2 from "./img/services-img-2.png";
import Services3 from "./img/services-img-3.png";

// Icons
import { HiArrowLongRight } from "react-icons/hi2";
// *******~ Import ~******** //
export default function FusionSelling(second) {
  const Content = [
    {
      Title: "Food & Beverage",
      Desc: "Tools that connect the front of house with the back of house and integrate everything that you need to run your restaurant into one dashboard.",
      Path: "/",
      img1: FoodImg1,
      img2: FoodImg2,
      img3: FoodImg3,
    },
    {
      Title: "Retailright",
      Desc: "Everything you need to build and run your e-commerce website with tools like inventory management, marketing and more.",
      Path: "/",
      img1: RetailImg1,
      img2: RetailImg2,
      img3: RetailImg3,
    },
    {
      Title: "Services & Othersright",
      Desc: "All the tools you need to deliver the best customer service with features like ePOS and CRM. Flexible for any use case.",
      Path: "/",
      img1: Services1,
      img2: Services2,
      img3: Services3,
    },
  ];
  const [hoveredItem, setHoveredItem] = useState(0);

  const handleItemHover = (index) => {
    setHoveredItem(index);
  };

  return (
    <>
      <section className="fusionselling-section">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <h3 className="heading">
                <span>Step into the world</span> of limitless <br /> selling
                with Fusion
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center">
            <Col xs={12} sm={12} md={12} lg={5} xl={6} xxl={5}>
              <ul className="fusionselling-content">
                {Content.map((data, index) => (
                  <>
                    <li
                      className={hoveredItem === index && "active"}
                      key={index}
                      onMouseEnter={() => handleItemHover(index)}
                      onMouseLeave={() => handleItemHover(index)}
                    >
                      <h3>
                        {data.Title}
                        <HiArrowLongRight />
                      </h3>
                      <p>{data.Desc}</p>
                      <button>Know More</button>
                    </li>
                  </>
                ))}
              </ul>
            </Col>
            <Col xs={12} sm={12} md={10} lg={7} xl={6} xxl={6}>
              <div className="fusionselling-img">
                {/* Render the images conditionally based on the hoveredItem */}

                {Content.map((data, index) => (
                  <>
                    {hoveredItem === index && (
                      <>
                        <Row key={index}>
                          <Col xxl={12} md={12}>
                            <Image data-aos="zoom-in" src={data.img1} fluid />
                          </Col>
                          <Col xxl={6} xs={6} md={6}>
                            <Image data-aos="zoom-in" src={data.img2} fluid />
                          </Col>
                          <Col xxl={6} xs={6} md={6}>
                            <Image data-aos="zoom-in" src={data.img3} fluid />
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
