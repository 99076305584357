// *******~ Import ~******** //
// React
// Assets
import { Container, Row, Col, Image } from "react-bootstrap";
// Components
import GetStartModal from "../../../../common/header/get-start/get-start";
// CSS
import "./slider.scss";
// Images
import SliderImg from "./img/slider-img1-copy.png";
// Icons
import { FaDotCircle } from "react-icons/fa";
// *******~ Import ~******** //
export default function Slider(second) {
  return (
    <>
      <section className="slider">
        <div class="pulse">
          <FaDotCircle />
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div className="content">
                <h1>Scale up your Business with Generative AI</h1>
                <ul>
                  <li>Point of Sale</li>
                  <li>Online Store</li>
                  <li>QR Solution</li>
                  <li>Inventory Management</li>
                  <li className="desk-hide">Payment Integration</li>
                  <li className="desk-hide">Delivery Integration</li>
                </ul>
                <ul className="mob-hide">
                  <li>Payment Integration</li>
                  <li>Delivery Integration</li>
                </ul>
                {/* <a href="tel:+442030263355" rel="noreferrer">
                  Get Started <span></span>
                </a> */}
                <span className="btn-group">
                  <GetStartModal text="Start free trail" clsname="first-btn" />
                  <GetStartModal text="Get a demo" />
                </span>
              </div>
            </Col>
            <Col xxl={12}>
              <div className="img-box">
                <Image src={SliderImg} fluid />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
