// *******~ Import ~******** //
// React
// Assets
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
// Components
import BlogData from "../../../blog/blogdata.json";
// CSS
import "./recent-blog.scss";
// Images
// import SmartFPOS from "./img/Smart-FusionPOS-Payment-Processing.jpg";
// import POSSystem from "./img/tips-for-using-a-pos-system-effectively.jpg";
// import BoostFPOS from "./img/FusionPOS-3-ways-to-boost-sale-in-a-downturn.jpg";
// Icons
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation, Autoplay, Mousewheel } from "swiper";
// *******~ Import ~******** //
export default function RecentBlog(second) {
  return (
    <>
      <section className="recent-blog">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <h3 className="heading">Recent Blogs</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Swiper
                modules={[Navigation, Autoplay, Mousewheel]}
                slidesPerView={3}
                spaceBetween={40}
                navigation={true}
                grabCursor={true}
                loop={true}
                mousewheel={false}
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                className="recent-swiper"
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  576: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1400: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                }}
              >
                {[...Array(3)].map((data, index) => (
                  <>
                    {BlogData.map((data, index) => (
                      <>
                        <SwiperSlide key={index}>
                          <div className="blog-box">
                            <Link to={`/blog/${data.path}`}>
                              <Image src={data.img} fluid />
                            </Link>

                            <div className="content">
                              <h3>{data.title}</h3>
                              <p>
                                {data.desc[0].subdesc
                                  .join(" ")
                                  .substring(0, 200) + "..."}
                              </p>
                              <div className="footer-div">
                                <div className="date-name">
                                  <span>
                                    By
                                    <a
                                      href={data.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {" "}
                                      {data.by}{" "}
                                    </a>
                                  </span>
                                  <span>{data.date}</span>
                                </div>
                                <Link to={`/blog/${data.path}`}>
                                  Read More{" "}
                                  <MdOutlineKeyboardDoubleArrowRight />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </>
                    ))}
                  </>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
